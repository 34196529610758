* {
    margin          : 0;
    padding         : 0;
    text-decoration : none;
}

/*upper nav content*/
div.app_nav_bar > div.nav {
    width : calc(100% - 240px);
}

/*sidebar*/
div.app_sidebar {
    height     : 100vh;
    width      : 240px;
    transition : all .5s ease;
    z-index    : 100;
    overflow-y : auto;
    box-shadow : 2px 2px 2px 2px black;
    background : var(--colorPrimaryDark);
}

/*upper nav bar*/
div.app_nav_bar {
    height     : 120px;
    text-align : center;
}

div.app_nav_bar img {
    width      : auto;
    max-width  : 100%;
    height     : auto;
    max-height : 100px;
    cursor     : pointer;
}

div.app_nav_bar div.user {
    color       : white;
    font-weight : 600;
    text-align  : center;
    font-size   : 80%;
}

div.app_sidebar a, div.app_sidebar div.header, div.app_sidebar span.header {
    display       : block;
    height        : 45px;
    width         : calc(100% - 10px);
    color         : white;
    background    : var(--colorPrimary);
    line-height   : 45px;
    padding-left  : 20px;
    box-sizing    : border-box;
    border-left   : 5px solid transparent;
    transition    : all .5s ease;
    cursor        : pointer;
    font-weight   : 500;
    margin        : 5px 0 0 5px;
    font-size     : 90%;

    text-overflow : ellipsis;
    overflow      : hidden;
    white-space   : nowrap;
}

div.app_sidebar a.active, div.app_sidebar a:hover, div.app_sidebar span.header.active, div.app_sidebar span.header:hover {
    border-left      : 5px solid var(--colorPrimary);
    color            : white;
    text-decoration  : none;
    background-color : var(--colorPrimaryDark)
}

div.app_sidebar a i, div.app_sidebar span.header i, div.app_sidebar span.header i, div.app_sidebar div.header i {
    font-size    : 17px;
    margin-right : 15px;
    width        : 20px;
}

div.app_sidebar span.sub_title {
    font-size : 14px;
}

/*the main content data*/
div.nav_content {
    position         : absolute;
    height           : calc(100vh);
    transition       : all .5s ease;
    padding          : 10px;
    left             : 240px;
    top              : 0;
    width            : calc(100% - 240px);
    background-color : var(--bgColor);
}

div.nav_content div.main_bar {
    height        : 40px;
    background    : whitesmoke;
    padding       : 5px;
    margin-bottom : 10px;
    border-radius : 5px;
}

div.nav_content div.main_bar > div.user_name {
    display        : inline-block;
    width          : calc(100% - 50px);
    height         : 30px;
    line-height    : 30px;
    vertical-align : middle;
    font-size      : 14px;
    color          : black;
    font-weight    : 600;
    margin-right   : 15px;
    text-align     : center;
}

div.nav_content > div.app_content {
    height     : 100%;
    overflow-y : auto;
}

@media (max-width : 768px) {
    div.nav_content > div.app_content {
        height : calc(100vh - 60px);
    }

    div.app_sidebar {
        left : -240px;
    }

    div.app_sidebar.open {
        left : 0;
    }

    div.nav_content {
        left  : 0;
        width : 100%;
    }

    div.nav_content.open {
        left  : 240px;
        width : calc(100% - 240px);
    }

    div.nav_content.open > div.app_content, div.nav_content.open > div.main_bar > div.user_name {
        display : none;
    }
}
