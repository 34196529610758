@import 'bootstrap-grid.css';
@import 'all.css';
@import 'nav_bar.css';
@import "index_md.css";

:root {
    --sidebar_width    : 250px;
    --colorPrimary     : #181717;
    --colorPrimaryDark : #000000;
    --colorAccent      : #5B0505;
    --bgColor          : #FFFFFF;
    --bgTiles          : #EEEEEE;
}

body {
    -webkit-font-smoothing  : antialiased;
    -moz-osx-font-smoothing : grayscale;
    margin                  : 0;
    padding                 : 0;
    height                  : auto;
    font-family             : 'Barlow', sans-serif;
    background              : white;
}

.w-100 {
    width : 100%;
}

.ui.table {
    margin : 0;
}

.bg_white {
    background : var(--bgTiles);
}

textarea, .ui.form textarea {
    resize : none;
}

button, input, optgroup, select, textarea {
    font-size : 13px;
}

.ui.input > input {
    padding : 6px 10px;
}

.ui.table thead {
    position   : sticky !important;
    top        : 0;
    z-index    : 2;
    background : var(--colorPrimaryDark);
}

.ui.table:not(.structured) thead, .ui.table:not(.structured) tbody tr {
    display      : table;
    width        : 100%;
    table-layout : fixed;
}

tbody tr:hover {
    cursor : pointer;
}

.ui.inverted.grey.table {
    background-color : #E2E2E2 !important;
    color            : #000000 !important;
}

.ui.inverted.striped.table tbody tr:nth-child(2n), .ui.inverted.striped.table > tr:nth-child(2n) {
    background-color : #D4D4D4
}

.ui.selectable.inverted.table tbody tr:hover {
    background : var(--colorPrimary) !important;
    color      : white !important;
}

.ui.inverted.striped.table tbody tr.row_rejected {
    pointer-events : none;
    background     : #585656;
}

.ui.negative.button, .ui.negative.buttons .button {
    background : #560C0C;
}

.ui.positive.button, .ui.positive.buttons .button {
    background : #153E05;
}

.ui.primary.button, .ui.primary.buttons .button {
    background : var(--colorPrimaryDark);
    border     : solid 1px transparent;
}

.ui.primary.button:hover, .ui.primary.buttons .button:hover {
    background-color : white;
    color            : var(--colorPrimaryDark);
    border-color     : var(--colorPrimaryDark);
}

.react-tel-input .form-control {
    height : 28px;
}

/*main content*/
div.content_bar {
    height         : 40px;
    background     : var(--bgTiles);
    border-radius  : 5px;
    padding        : 5px;
    margin-bottom  : 10px;
    display        : flex;
    flex-direction : row;
}

div.content_bar div.search_bar, div.content_bar div.content_buttons {
    height         : 100%;
    vertical-align : top;
}

div.content_bar div.search_bar {
    margin-right : 5px;
    flex         : 1;
}

div.content_bar > div.search_bar div.search_check {
    height         : 28px;
    line-height    : 28px;
    vertical-align : middle;
    width          : 150px;
    background     : white;
    border-radius  : 5px;
    padding        : 0 5px;
}

div.content_bar > div.search_bar > div {
    display        : inline-block;
    width          : 140px;
    margin-right   : 5px;
    vertical-align : top;
}

div.content_bar > div.search_bar > div.group_name {
    width : calc(100% - 155px);
}

div.content_bar > div.search_bar > div.dropdown {
    width : 33px;
}

div.content_bar > div.search_bar > div.dropdown > div.drop_list {
    height     : auto;
    border     : solid 1px lightgrey;
    z-index    : 100;
    padding    : 10px;
    position   : absolute;
    top        : 42px;
    background : var(--bgTiles);
    display    : none;
    width      : 250px;
    max-height : calc(100vh - 200px);
    overflow-y : initial;
}

div.content_bar > div.search_bar > div.dropdown > div.drop_list.list_only {
    overflow-y : auto;
}

div.content_bar > div.search_bar > div.dropdown:hover div.drop_list,
div.content_bar > div.search_bar > div.dropdown div.drop_list:hover {
    display : block;
}

div.content_bar > div.search_bar > div.dropdown > div.drop_list > *:not(:last-child) {

}

div.content_bar > div.search_bar > div.dropdown > div.drop_list label {
    font-weight : 600;
    margin-top  : 10px;
    font-size   : 12px;
    display     : block;
}

div.content_bar > div.search_bar > div.dropdown > div.drop_list > div.ui.checkbox {
    display : block;
    width   : 100%;
}

div.content_bar div.content_buttons {
    text-align : right;
}

div.content_bar div.content_buttons button {
    margin-left    : 5px;
    display        : inline-block;
    vertical-align : top;
}

div.content_bar > div.content_buttons > div.payment_button {
    width          : 70px;
    height         : 100%;
    margin-left    : 5px;
    display        : inline-block;
    vertical-align : top;
}

div.content_bar > div.content_buttons > div.payment_button button {
    width          : 100%;
    margin-left    : 0;
    display        : block;
    vertical-align : top;
}

/*table information*/
div.table_container {
    height        : calc(100% - 50px);
    overflow-y    : auto;
    border-radius : 5px;
    background    : var(--bgTiles);
    overflow-x    : auto;
}

div.table_container.cashier_receive {
    height        : calc(100% - 80px);
    margin-bottom : 10px;
}

div.table_container.has_footer {
    height        : calc(100% - 90px);
    margin-bottom : 5px;
}

div.table_container.table_100 {
    height : 100%;
}

div.table_container.igg_igm {
    height        : calc(100% - 90px);
    margin-bottom : 5px;
}

div.table_container.controls {
    height        : calc(100% - 55px);
    background    : white;
    margin-bottom : 10px;
}

div.table_container table {
    min-width : 100%;
}

div.table_container.user_container table {
    width : 550px;
}

div.table_container.group_container table {
    width : 650px;
}

div.table_container.family_container {
    height        : calc(100% - 50px);
    background    : whitesmoke;
    margin-bottom : 10px;
}

div.table_container.family_container table {
    width : 400px;
}

div.table_container.results_lab_container table {
    width : 760px;
}

div.received_container {
    flex       : 1;
    background : white;
    overflow-y : auto;
}

div.received_container table {
    width : 250px;
}

div.table_container.group_members {
    height        : calc(100% - 40px);
    background    : whitesmoke;
    margin-bottom : 10px;
}

div.table_container.group_payments table {
    width : 560px;
}

div.table_container.group_payments {
    height     : 100%;
    background : whitesmoke;
}

div.table_container.group_pending_invoices {
    height        : calc(100% - 40px);
    margin-bottom : 10px;
    background    : whitesmoke;
}

div.table_container.group_pending_invoices table {
    width : 560px;
}

div.table_container.company_payments_table_container table {
    width : 1000px;
}

div.table_container.test_report_container table {
    width : 1000px;
}

/*select input*/
.ui.dropdown, .ui.selection.dropdown {
    height         : 30px;
    min-height     : 30px;
    line-height    : 30px;
    vertical-align : middle;
    padding        : 0 15px;
}

.ui.dropdown, .ui.selection.dropdown .text {
    font-size : 13px;
}

.ui.selection.dropdown div.text {
    text-overflow : ellipsis;
    overflow      : hidden;
    white-space   : nowrap;
    width         : calc(100% - 10px);
    height        : 100%;
}

.ui.selection.dropdown > .delete.icon, .ui.selection.dropdown > .dropdown.icon, .ui.selection.dropdown > .search.icon {
    height  : 30px;
    padding : 8px 5px;
    margin  : -10px -10px 0 0;
}

/*message*/
.ui.message {
    line-height : 20px;
    padding     : 5px 10px;
    font-size   : 95%;
}

.ui.message p {
    font-weight : 600;
}

/*message dialogs*/
div.dialog_container {
    padding : 20px;
}

div.dialog_container > div.dialog_icon {
    text-align    : center;
    margin-bottom : 20px;
    font-size     : 250%;
}

div.dialog_container > div.dialog_message {
    font-size      : 110%;
    line-height    : 25px;
    vertical-align : middle;
    text-align     : center;
}

div.verify_all_table {
    height     : auto;
    max-height : calc(100vh - 150px);
    overflow-y : auto;
    background : var(--bgTiles);
}

/*create a test*/
div.diagnosis_data_container {
    height : calc(100% - 50px);
}

div.button_data {
    height : 30px;
}

div.diagnosis_data_container > div {
    height : 100%;
}

div.diagnosis_data_container > div > div.segment {
    height : calc(100% - 10px);
}

div.diagnosis_data_container > div > div.segment > div.test_data {
    height : calc(100%);
}

div.diagnosis_data_container div.triage {
    height     : 100%;
    overflow-y : auto;
}

div.diagnosis_data_container div.triage div.test_data {
    height : calc(100% - 40px);
}

div.diagnosis_data_container div.test_data {
    overflow-y : auto;
}

div.diagnosis_data_container div.diagnosis_discount_reason {
    font-size  : 80%;
    margin-top : 5px;
    background : #EFEBEB;
    padding    : 2px 5px;
}

div.diagnosis_data_container div.table_container {
    height        : calc(100% - 180px);
    margin-bottom : 10px;
}

div.payment_methods {
    text-align    : center;
    margin-bottom : 10px;
}

div.payment_methods > div, div.payment_methods > div {
    display      : inline-block;
    height       : 30px;
    width        : 50px;
    margin-right : 5px;
    border       : solid 2px transparent;
    cursor       : pointer;
}

div.payment_methods img, div.payment_methods img {
    width  : 100%;
    height : 100%;
    border : solid 1px lightgrey;
}

div.payment_methods > div:hover, div.payment_methods > div:hover, div.payment_methods div.pesapal_button:hover {
    border-color : black;
}

div.diagnosis_data_container div.pesapal_button {
    height        : 30px;
    margin-bottom : 5px;
    cursor        : pointer;
    border        : solid 2px transparent;
    width         : auto;
    display       : inline-block;
}

div.diagnosis_data_container div.pesapal_button > img {
    height  : 100%;
    width   : auto;
    border  : solid 1px lightgrey;
    padding : 2px;
}

div.test_info {
    background    : white;
    padding       : 5px;
    margin-bottom : 5px;
    height        : 100%;
    border-radius : 5px;
    overflow-y    : auto;
}

div.test_data {
    background : whitesmoke;
    padding    : 10px;
}

div.test_header {
    padding       : 5px 10px;
    color         : white;
    background    : var(--colorPrimaryDark);
    margin-top    : 10px;
    margin-bottom : 5px;
    border-radius : 5px;
    font-weight   : 600;
    font-size     : 90%;
}

div.test_sub_header {
    color         : var(--colorPrimaryDark);
    margin-top    : 10px;
    margin-bottom : 5px;
    font-weight   : 600;
    font-size     : 90%;
}

div.diagnosis_test {
    margin-top    : 5px;
    margin-bottom : 5px;
    padding       : 5px;
    background    : white;
}

div.test_result {
    background     : whitesmoke;
    padding        : 10px;
    height         : 100%;
    flex-direction : column;
    display        : flex;
}

div.laboratory_modal {
    height     : calc(100vh - 80px);
    background : white;
    padding    : 10px;
}

div.laboratory_modal > div {
    height : 100%;
}

div.laboratory_modal > div div.tab {
    height : calc(100% - 60px);
}

div.laboratory_modal div.tab div.modal_content {
    height         : 100%;
    display        : flex;
    flex-direction : column;
}

div.results {
    height : 550px;
}

div.test_result.controls {
    height : calc(100vh - 100px);
}

div.lab_form {
    flex           : 1;
    padding        : 10px;
    margin-bottom  : 10px;
    background     : var(--bgTiles);
    display        : flex;
    flex-direction : column;
}

div.targets_form {
    padding       : 10px;
    margin-bottom : 10px;
    background    : var(--bgTiles);
    height        : calc(100% - 40px);
}

div.targets_form div.table_container {
    height : calc(100% - 220px);
}

div.test_slip {
    height : calc(100vh - 100px);
}

div.label {
    font-weight : 600;
    font-size   : 90%;
    color       : var(--colorPrimaryDark);
}

div.verify_container {
    padding    : 10px;
    height     : calc(100vh - 150px);
    overflow-y : auto;
    background : whitesmoke;
}

div.verify_container div.diagnosis_no {
    font-size   : 16px;
    font-weight : bold;
    text-align  : center;
}

div.verify_container div.verify_data {
    height        : calc(100% - 75px);
    margin-bottom : 10px;
    margin-top    : 10px;
    overflow-y    : auto;
    background    : white;
    padding       : 10px;
}

div.verify_container div.verify_data > div.ui, div.verify_container div.other_results {
    display       : block;
    width         : 100%;
    padding       : 5px;
    background    : var(--bgTiles);
    margin-bottom : 5px;
}

div.verify_container div.label {
    margin-top    : 15px;
    margin-bottom : 5px;
}

div.verify_container div.label_info {
    font-size     : 14px;
    color         : black;
    margin-bottom : 10px;
}

div.verify_container div.other_results {
    margin-top : 5px;
}

div.verify_container div.table_container {
    height : auto;
}

div.verify_container div.verify_button {
    background : white;
    padding    : 5px;
}

div.pricing_container {
    background : white;
    padding    : 10px;
}

div.pricing_container div.table_container {
    height        : auto;
    margin-bottom : 10px;
}

/*client information*/
div.user_info_form {
    height        : calc(100% - 40px);
    margin-bottom : 10px;
    overflow-y    : auto;
}

/*user information*/
div.user_info {
    background    : var(--bgTiles);
    padding       : 10px;
    margin-top    : 5px;
    margin-bottom : 5px;
    border-radius : 5px;
}

div.app_data_form {
    height        : calc(100% - 50px);
    overflow-y    : auto;
    padding       : 5px;
    border-radius : 5px;
}

div.app_data_button {
    height        : 40px;
    margin-top    : 10px;
    padding       : 5px;
    border-radius : 5px;
}

input.date_input {
    width          : 100%;
    height         : 29px;
    padding        : 0 10px;
    line-height    : 29px;
    vertical-align : middle;
    border-radius  : 5px;
    border         : solid 1px lightgray;
}

input.date_input:focus {
    border  : solid 1px #C3C1C1;
    outline : none;
}

div.user_data_container {
    margin-bottom : 15px;
}

.react-tel-input {
    font-size : 12px;
}

.react-tel-input .form-control {
    width : 100%;
}

.react-tel-input .country-list {
    width : 200px;
}

/*login*/
div.login_window {

}

div.login_window div.login_logo {
    text-align : center;
}

div.login_window div.login_logo img {
    max-width     : 100%;
    width         : auto;
    height        : auto;
    max-height    : 200px;
    margin-bottom : 20px;
}

.ui.form .error.message.show, .ui.form .success.message.show, .ui.form .warning.message.show {
    display       : block;
    margin-bottom : 4px;
    padding       : 5px 10px;
    font-size     : 12px;
}

span.login_message {
    display         : block;
    width           : 100%;
    text-align      : center;
    color           : black;
    font-weight     : 600;
    font-size       : 80%;
    text-decoration : underline;
}

span.login_message:hover {
    cursor : pointer;
    color  : var(--colorAccent);
}

div.searched_users {
    height     : 100%;
    padding    : 10px;
    background : white;
    overflow-y : auto;
}

div.searched_users div.assistant_payment {
    height        : calc(100% - 40px);
    margin-bottom : 10px;
}

.home img {
    width       : 40%;
    margin-left : 30%;
    height      : auto;
    margin-top  : 10vh;
}

.home .home_welcome {
    text-align  : center;
    font-weight : 600;
    font-size   : 150%;
    margin-top  : 20vh;
}

.h-100 {
    height : 100%;
}

.input_file {
    width    : 0.1px;
    height   : 0.1px;
    opacity  : 0;
    overflow : hidden;
    position : absolute;
    z-index  : -1;
}

/*client management*/
div.client_modal {
    background : white;
    padding    : 10px;
}

div.client_modal div.modal_header {
    margin-bottom : 10px;
    font-weight   : 600;
    font-size     : 150%;
    text-align    : center;
}

div.client_modal div.label {
    margin-bottom : 5px;
}

/*NOT FOUND*/
div.no_match {
    background : var(--colorPrimaryDark);
    color      : #FFFFFF;
    height     : 100vh;
    overflow   : hidden;
}

div.no_match .c {
    text-align : center;
    display    : block;
    position   : relative;
    width      : 80%;
    margin     : 100px auto;
}

div.no_match ._404 {
    font-size      : 220px;
    position       : relative;
    display        : inline-block;
    z-index        : 2;
    height         : 250px;
    letter-spacing : 15px;
}

div.no_match ._1 {
    text-align     : center;
    display        : block;
    position       : relative;
    letter-spacing : 12px;
    font-size      : 4em;
    line-height    : 80%;
}

div.no_match ._2 {
    text-align : center;
    display    : block;
    position   : relative;
    font-size  : 20px;
}

div.no_match .btn {
    background-color : rgb(255, 255, 255);
    position         : relative;
    display          : inline-block;
    width            : 358px;
    padding          : 5px;
    z-index          : 5;
    font-size        : 25px;
    color            : var(--colorPrimaryDark);
    border-radius    : 5px;
    text-decoration  : none;
    margin           : 10px 10px;
}

div.no_match hr {
    padding    : 0;
    border     : none;
    border-top : 5px solid #FFFFFF;
    color      : #FFFFFF;
    text-align : center;
    margin     : 0 auto;
    width      : 420px;
    height     : 10px;
    z-index    : -10;
}

/*group pricing*/
div.ui.modal.company_modal {
    background : transparent;
}

div.ui.modal.company_modal div.segment {
    height : 80vh;
}

div.group_pricing {
    height         : 100%;
    width          : 100%;
    color          : white;
    text-align     : center;
    font-weight    : 600;
    line-height    : 30px;
    vertical-align : middle;
    border-radius  : 5px;
    background     : black;
    font-size      : 80%;
}

div.group_pricing.positive {
    background : #174A17;
}

div.group_pricing.negative {
    background : #961823;
}

div.group_payment_form:nth-child(1) {
    height     : 225px;
    width      : 100%;
    background : whitesmoke;
    padding    : 5px 5px;
}

div.group_payment_form:nth-child(2) {
    margin-top : 10px;
    height     : calc(100% - 235px);
    overflow-y : auto;
}

div.family_link {
    position       : absolute;
    right          : 50px;
    bottom         : 60px;
    border-radius  : 50%;
    background     : var(--colorPrimaryDark);
    height         : 50px;
    width          : 50px;
    color          : whitesmoke;
    text-align     : center;
    vertical-align : middle;
    line-height    : 50px;
    cursor         : pointer;
    z-index        : 100;
}

div.family_form {
    height        : calc(100% - 50px);
    padding       : 10px;
    background    : whitesmoke;
    margin-bottom : 10px;
    overflow-y    : auto;
}

div.family_form div.label {
    margin-bottom : 5px;
}

input.hidden_for {
    height  : 1px;
    width   : 1px;
    display : none;
}

.blur {
    filter         : blur(3px);
    pointer-events : none;
}

div.modify_payments_container {
    padding    : 10px;
    background : white;
}

div.modify_payments div.table_container {
    height     : 170px;
    margin-top : 10px;
}

div.modify_payments div.modify_payments_money {
    font-weight   : 600;
    font-size     : 90%;
    background    : var(--colorPrimaryDark);
    color         : white;
    padding       : 5px;
    border-radius : 5px;
}

div.upload_results {
    width          : 40px;
    height         : 40px;
    position       : absolute;
    bottom         : 50px;
    right          : 50px;
    background     : black;
    border-radius  : 50%;
    text-align     : center;
    vertical-align : middle;
    line-height    : 40px;
}

div.upload_results label {
    color  : white;
    cursor : pointer;
}

div.csv_input {
    width          : 100%;
    height         : 30px;
    background     : black;
    border-radius  : 5px;
    text-align     : center;
    vertical-align : middle;
    line-height    : 30px;
}

div.csv_input label {
    color     : white;
    cursor    : pointer;
    font-size : 14px;
}

div.csv_input label i {
    font-size : 15px;
}

.ui.received.button {
    background : darkgreen;
}

.ui.pending.button {
    background : orangered;
}

.ui.bounced.button {
    background : darkred;
}

.ui.server_uploaded.button {
    background     : darkgreen;
    pointer-events : none;
}

.ui.server_missing.button {
    background     : darkred;
    pointer-events : none;
}

.toasts-container .toast.toast-info {
    background : blue !important;
    color      : white !important;
}

/*center info*/
img.center_logo {
    width      : auto;
    max-width  : 100%;
    min-width  : 150px;
    height     : auto;
    min-height : 100px;
    margin     : auto auto;
    border     : solid 1px lightgrey;
}

div.passport_photo_container {
    padding : 0 calc((100% - 170px) / 2);
}

img.client_passport_photo {
    height : 140px;
    border : solid 1px lightgray;
    width  : 100%;
}

div.float_window {
    position       : absolute;
    right          : 50px;
    bottom         : 100px;
    width          : 50px;
    height         : 50px;
    border-radius  : 50%;
    z-index        : 2;
    text-align     : center;
    vertical-align : center;
    line-height    : 50px;
}

div.float_window .ui.button {
    border-radius : 50%;
}

/*pesapal payment*/
div.initiate_payment_container {
    text-align : center;
    margin-top : 20px;
}

div.initiate_payment_container a {
    background    : var(--colorPrimaryDark);
    padding       : 5px 15px;
    border-radius : 3px;
    color         : white;
    font-size     : 12px;
    border        : solid 1px var(--colorPrimaryDark);
}

div.initiate_payment_container a:hover {
    color      : var(--colorPrimaryDark);
    background : white;
}

div.init_payment_window {
    background : var(--colorPrimaryDark);
    height     : 100vh;
    width      : 100%;
    padding    : 20px;
}

div.init_payment_window div.payment_form {
    border        : solid 2px white;
    padding       : 10px;
    width         : 400px;
    margin-left   : calc((100% - 400px) / 2);
    border-radius : 10px;
    background    : white;
}

div.init_payment_window div.frame {
    border     : solid 1px white;
    padding    : 5px;
    margin-top : 10px;
    height     : calc(100% - 40px);
    background : white;
}

div.init_payment_window div.reference_no {
    font-weight : 700;
    color       : white;
    height      : 30px;
    text-align  : center;
    font-size   : 20px;
}

div.upload_modal {
    padding : 15px;
}

div.upload_modal div.header {
    font-weight   : 600;
    font-size     : 14px;
    margin-bottom : 10px;
}

div.upload_modal div.content {
    margin-bottom : 10px;
}

div.lab_igg_igm_modal {
    padding : 10px;
    height  : calc(100vh - 150px);
}

div.lab_igg_igm_modal div.content {
    height        : calc(100% - 40px);
    margin-bottom : 10px;
    background    : whitesmoke;
    padding       : 10px;
}

div.create_covid_test {
    height  : auto;
    padding : 10px;
}

div.create_covid_test div.form_window {
    background    : whitesmoke;
    margin-bottom : 10px;
    padding       : 10px;
}

div.create_covid_test div.form_window > div {
    background    : white;
    margin-bottom : 10px;
}

div.create_covid_test div.form_window > div:last-child {
    margin-bottom : 0;
}

div.csv_modal, div.account_modal, div.create_test_modal, div.capture_image_modal {
    height     : calc(100vh - 50px);
    background : white;
    padding    : 10px;
}

div.capture_image_modal > div.image_form {
    margin-bottom : 10px;
    height        : calc(100% - 40px);
    overflow-y    : auto;
}

div.response_modal {
    max-height : calc(100vh - 50px);
    background : white;
    padding    : 10px;
    height     : auto;
}

div.popup_window {
    position         : relative;
    display          : inline-block;
    width            : 160px;
    background-color : #555555;
    color            : #FFFFFF;
    text-align       : center;
    border-radius    : 6px;
    padding          : 8px 0;
    z-index          : 10000;
    top              : 10px;
    left             : 10px;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {
        opacity : 0;
    }
    to {
        opacity : 1;
    }
}

@keyframes fadeIn {
    from {
        opacity : 0;
    }
    to {
        opacity : 1;
    }
}

/*dashboard*/
div.dash_container {
    height        : 120px;
    border-radius : 5px;
    padding       : 10px;
    color         : white;
}

div.dash_container.female {
    background : #060653;
}

div.dash_container.male {
    background : #153E05;
}

div.dash_container.others {
    background : #AE3509;
}

div.dash_container div.header {
    font-weight    : 500;
    font-size      : 100%;
    margin-bottom  : 10px;
    text-align     : center;
    text-transform : uppercase;
}

div.dash_container div.count {
    font-weight : 600;
    font-size   : 180%;
    text-align  : center;
}

div.dash_container div.dash_divider {
    margin     : 10px 0 0 0;
    border-top : dashed 1px white;
}

div.dash_container div.sub_content {
    margin       : 5px 0 5px 0;
    border-right : dashed 1px white;
}

div.dash_container div.sub_content:last-child {
    border-right : none;
}

div.dash_container div.sub_content div.sub_header {
    font-weight : 700;
    font-size   : 12px;
    text-align  : center;
}

div.dash_container div.sub_content div.sub_count {
    text-align  : center;
    font-size   : 14px;
    font-weight : 600;
}

div.dash_graph {
    height     : 400px;
    padding    : 10px;
    background : whitesmoke;
}
