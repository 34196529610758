@media (max-width : 768px) {
    div.content_bar > div.search_bar div.hide_small {
        display : none;
    }

    div.content_bar > div.search_bar div.hide_large {
        display : block;
    }

    div.app_data_form {
        height : auto;
    }

    div.family_form {
        height : auto;
    }

    div.content_bar > div.search_bar div.search_check {
        width : 100%;
    }

    div.content_bar div.content_buttons button {
       /* width : 35px;*/
    }
}

@media (min-width : 768px) {
    div.content_bar > div.search_bar div.hide_large {
        display : none;
    }

    div.content_bar div.content_buttons button {
       /* width : 145px;*/
    }
}
